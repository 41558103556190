// Generated by Framer (c747e62)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["mgE71h2UF", "etRAxRdpS", "HJ3atvBLj"];

const serializationHash = "framer-pdDOU"

const variantClassNames = {etRAxRdpS: "framer-v-nctzy4", HJ3atvBLj: "framer-v-g215zt", mgE71h2UF: "framer-v-n97ugy"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {Desktop: "mgE71h2UF", Phone: "HJ3atvBLj", Tablet: "etRAxRdpS"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, Q7PcH1nEK: title ?? props.Q7PcH1nEK ?? "Section + container-large", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "mgE71h2UF"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, Q7PcH1nEK, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "mgE71h2UF", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-n97ugy", className, classNames)} data-framer-name={"Desktop"} layoutDependency={layoutDependency} layoutId={"mgE71h2UF"} ref={ref ?? ref1} style={{backgroundColor: "var(--token-88b875e7-8ca4-4532-b8a8-281609a0f010, rgb(15, 30, 53))", ...style}} {...addPropertyOverrides({etRAxRdpS: {"data-framer-name": "Tablet"}, HJ3atvBLj: {"data-framer-name": "Phone"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7SzJELXJlZ3VsYXI=", "--framer-font-family": "\"K2D\", \"K2D Placeholder\", sans-serif", "--framer-font-size": "22px", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Section + container-large</motion.p></React.Fragment>} className={"framer-1d7c3ni"} fonts={["GF;K2D-regular"]} layoutDependency={layoutDependency} layoutId={"iGoDsWBjd"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} text={Q7PcH1nEK} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-pdDOU.framer-brcwjl, .framer-pdDOU .framer-brcwjl { display: block; }", ".framer-pdDOU.framer-n97ugy { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 40px; justify-content: center; overflow: hidden; padding: 20px; position: relative; width: 1200px; }", ".framer-pdDOU .framer-1d7c3ni { flex: 1 0 0px; height: auto; position: relative; white-space: pre-wrap; width: 1px; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-pdDOU.framer-n97ugy { gap: 0px; } .framer-pdDOU.framer-n97ugy > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-pdDOU.framer-n97ugy > :first-child { margin-left: 0px; } .framer-pdDOU.framer-n97ugy > :last-child { margin-right: 0px; } }", ".framer-pdDOU.framer-v-nctzy4.framer-n97ugy { width: 810px; }", ".framer-pdDOU.framer-v-g215zt.framer-n97ugy { width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 1200
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"etRAxRdpS":{"layout":["fixed","fixed"]},"HJ3atvBLj":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"Q7PcH1nEK":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramereJHHw7DUN: React.ComponentType<Props> = withCSS(Component, css, "framer-pdDOU") as typeof Component;
export default FramereJHHw7DUN;

FramereJHHw7DUN.displayName = "Styleguide/Title";

FramereJHHw7DUN.defaultProps = {height: 40, width: 1200};

addPropertyControls(FramereJHHw7DUN, {variant: {options: ["mgE71h2UF", "etRAxRdpS", "HJ3atvBLj"], optionTitles: ["Desktop", "Tablet", "Phone"], title: "Variant", type: ControlType.Enum}, Q7PcH1nEK: {defaultValue: "Section + container-large", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramereJHHw7DUN, [{explicitInter: true, fonts: [{family: "K2D", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/k2d/v11/J7aTnpF2V0ETcq8tnLcg7w.woff2", weight: "400"}]}], {supportsExplicitInterCodegen: true})